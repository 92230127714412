import React from "react";
import styled from "styled-components";
import Block from "../_global/editorial/Block";
import RoundButton from "../_global/RoundButton";
import Spacer from "../_global/layout/Spacer";

const Comp = styled.div`
  background-color: ${({ theme }) => theme.colors.titanWhite};
  flex: 1;
  //min-width: 20rem;
  //flex-shrink: 0.5;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
`;

const Button = styled(RoundButton)`


  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    align-self: center;
  }
`;

const LinkBlock = (props) => {
	const { text, button } = props;
	// console.log(button.internalLink);
	return (
		<Comp>
			<Block data={text} />
			<Spacer s="medium" />
			<Button data={button} type="pill" />
			<Spacer s="medium" />
		</Comp>
	);
};

export default LinkBlock;
