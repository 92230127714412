import React from "react";
import { graphql } from "gatsby";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Block from "../components/_global/editorial/Block";
import Layout from "../components/_global/layout/Layout";
import localize from "../components/_global/localization/localize";
import Spacer from "../components/_global/layout/Spacer";
import LinkBlock from "../components/7_babysitters/LinkBlock";
import styled from "styled-components";

const LinkBlocksContainer = styled(Section)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    flex-direction: column;
  }
`;

const Babysitters = ({ data, ...props }) => {
	const { page } = data;
	return (
		<Layout title={page.common.title}>
			<PageHeader title={page.common.title} image={page.splashImage} />

			<Section m="large">
				<Block data={page.text} />
			</Section>
			<Spacer s="large" />

			<LinkBlocksContainer m="small">
				<LinkBlock text={page.babysitterText} button={page.babysitterButton} />
				<Spacer s="medium" />
				<LinkBlock text={page.parentText} button={page.parentButton} />
			</LinkBlocksContainer>

			<Spacer s="large" />
		</Layout>
	);
};

export default localize(Babysitters);

export const query = graphql`
    {
        page: sanityBabysitters {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            splashImage
            {
                ...
                ImageFragment
            }
            text {
                ...CompleteBlockFragment
            }
            babysitterText {
                ...CompleteBlockFragment
            }
            babysitterButton {
                ...LinkFragment
            }
            parentText {
                ...CompleteBlockFragment
            }
            parentButton {
                ...LinkFragment
            }
        }
    }
`;